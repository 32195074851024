<template>
<v-col cols="12" class="home-content">
  
  <v-row>
    <v-col cols="11" sm="12" md="12" lg="12" class="mx-auto pb-0 header-section text-center mt-0 mb-5">
      <router-link to="/">
        <v-img src="../../assets/img/monnaie_pirate_logo.png" class="d-none d-sm-inline-flex" width="150"/>
        <v-img src="../../assets/img/monnaie_pirate_logo.png" class="d-inline-flex d-sm-none" width="100"/>
      </router-link>
      <br><br>
      <h1 class="font-main pb-1 pt-5 mb-5 pb-5 yellow--text text--darken-2" @click="initData">
        Journal de bord
      </h1>
    </v-col>

    <v-col cols="11" sm="8" md="6" lg="5" class="mx-auto pt-0 header-section text-center mt-0 mb-5">
        <small class="font-papyrus">
          Le journal de bord rassemble tous des documents transmis par Black Sam et Alvilda, 
          pour raconter comment ils ont inventé <i>la Monnaie Pirate</i> depuis le royaume des Morts, 
          et tenter d'expliquer comment la mettre en place dans le monde réel...
        </small>
    </v-col>
  </v-row>

  <v-row style="">
    <v-col cols="12" sm="11" md="11" lg="8" class="mx-auto text-left px-0" style="max-width: 1000px;">
      <v-row style="margin:0px;">
        <template v-for="(post, p) in posts">
          <v-toolbar :key="p" v-if="post.categories[0].name == 'journal de bord'"
                      class="font-main px-4 pb-md-2 toolbar-sommaire"
                      color="transparent" elevation="0"
                      :height="$vuetify.breakpoint.width < 600 ? 60 : 120"
                      @click="$router.push('/post/'+post.position)">
            <div class="title-sommaire font-papyrus">
              <span class="position">{{ post.position }}#</span>
              <small class="d-none d-md-inline px-5 font-main">{{ post.title }}</small>
              <small class="d-md-none px-2">{{ post.title }}</small>
              <!-- <span class="font-dancing d-none d-md-inline" style="font-size:16px;">{{ getDateStory(post) }}</span>
              <span class="font-dancing ml-4 d-md-none" style="font-size:16px;">{{ getDateStoryShort(post) }}</span> -->
            </div>
            <v-spacer class="spacer-dots"/>
            <v-btn icon outlined class="d-none d-sm-inline"><v-icon>mdi-chevron-right</v-icon></v-btn>
            <v-btn icon outlined class="d-sm-none" small><v-icon>mdi-chevron-right</v-icon></v-btn>
          </v-toolbar>
        </template>
      </v-row>
    </v-col>

  </v-row>

  <v-row v-if="posts.length > 0">

    <v-col cols="12" sm="11" md="11" lg="8" class="mx-auto px-5 text-left article" style="max-width: 1000px;">
      <v-divider/>
      <h2 class="font-hand mt-5 maintitle">
              <span class="font-papyrus">1#</span> {{ posts[0].title }}
      </h2><br>
      <p class="font-papyrus paragraf" v-html="nl2br(posts[0].text)"></p>

    </v-col>

    <v-col cols="12" class="text-center">
      <v-btn x-large class="font-dancing" to="/post/2"
            style="font-size:30px;" rounded outlined>
        Lire la suite <v-icon large>mdi-chevron-right</v-icon>
      </v-btn>
    </v-col>
    
  </v-row>

  <PostRow/>

</v-col>
</template>


<script>
  import core from '../../plugins/core.js'

  import '@/assets/css/blog.css'
  import PostRow from '@/components/main-layout/PostRow.vue'
  //import moment from 'moment'
  
  //import { i18n } from 'vue-lang-router';

  export default {
    name: "App",
    components: {
      PostRow
    },
    data: function () {
        return {
            posts: [],
            postToShow: false,
            showPostDialog: false,
        };
    },
    mounted: function(){
      this.$store.dispatch('app/incStat', '/book')
      this.initData()
      this.$root.$on('closeDialogPost', () => { this.closeDialogPost() })

    },
    methods: {
        
        async initData(){
           console.log('diffSecs');
          await this.$store.dispatch('app/fetchEntities', { entityType: "blogPost", query: { public: true }, sort: 'position' })
          this.posts = this.$store.state.app.data.blogPost

          console.log("Posts", this.posts)
        },

        nl2br: function(str, is_xhtml){
          return core.nl2br(str, is_xhtml)
        },

        baseUrl(){ return core.baseUrl() },


        getCountDay(post){
          return core.getCountDay(post)
        },
        // getDateStory(post){
        //   return core.getDateStory(post)
        // },
        // getDateStoryShort(post){
        //   return core.getDateStoryShort(post)
        //}
    },
    computed:{
      // diffDay(){ return moment(new Date('07/14/2022')).diff(moment(new Date()), 'days') },
      // diffHours(){ return 24 - (new Date()).getHours() },
      // diffMin(){ return 60 - (new Date()).getMinutes() },
      // diffSec: () => { console.log('diffSec'); return (60 - (new Date()).getSeconds()) }
    },
    watch: {
      '$store.state.app.data.post' : { immediate: true, 
        async handler () {
            this.initData()
        }
      },
    }
  };
</script>

